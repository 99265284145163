.arrivals-board-container {
  background-color: #000000;
  height: 100%;
}
.ab_table_container {
  width: 100%;
  height: 92%;
  margin-top: 8px;
  margin-top: 24px;
}
.text-gray {
  color: gray !important;
}

.text-green {
  color: #00bd78 !important;
}

.text-white {
  color: #fff5e1 !important;
}

.text-green .ready-cell {
  color: #24282a !important;
  background-color: #00bd78 !important;
}

/* .text-orange {
  color: orange !important;
}
 */
.text-orange .ready-cell {
  color: white !important;
  background-color: orange !important;
}

.text-blue {
  color: #66a8ff !important;
}

.text-blue .ready-cell {
  color: #24282a !important;
  background: #66a8ff !important;
}

.text-white .ready-cell {
  color: #24282a !important;

  background: #fff5e1 !important;
}

.text-green-source-cell {
  color: white;
  background-color: #00a700;
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
}

.text-orange-source-cell {
  color: white;
  background-color: orange;
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
}

.text-white-source-cell {
  color: black !important;
  background-color: white !important;
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
}

.text-blue-source-cell {
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  color: white !important;
  background-color: #5981dc !important;
}

.border-blue {
  border-bottom: 6px solid blue !important;
}
.border-blue .ag-cell {
  border-bottom-color: #222627;
}
.border-blue {
  border-bottom: 4px solid #66a8ff !important;
  height: 100%;
}

.border-blue:last-child::after {
  position: absolute !important;
  top: calc(100% - 6.5px);
  content: "";
  border-top: 8px solid transparent;
  border-left: 14px solid #66a8ff !important;
  border-bottom: 8px solid transparent;
}

.border-blue:not(.ag-row:last-child) + div::after {
  position: absolute !important;
  bottom: calc(100% - 6.5px);
  content: "";
  border-top: 8px solid transparent;
  border-left: 15px solid #66a8ff !important;
  border-bottom: 8px solid transparent;
}

.ifb-cell,
.room-cell {
  width: 75%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
}

.ifb-cell {
  background-color: rgb(73, 73, 73);
}

.room-cell {
  background-color: white;
  color: black;
}

.ab-heading {
  color: #f7fa3d;
  text-align: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

.ab-control-room-view .ab-heading {
  font-size: 42px;
}

.ag-theme-alpine-dark .ag-cell {
  padding-left: 18px;
  padding-right: 18px;
}

.ag-header-row {
  background-color: grey !important;
}

.ab-datetime {
  color: #ffffff;
  text-align: right;
  margin: 0;
  font-size: 14px;
  padding-right: 8px;
  display: inline;
  position: absolute;
  right: 0;
}

.ab-control-room-view .ab-datetime {
  font-size: 24px;
  top: 44px;
}

.ab-refresh-detail {
  margin-right: 0.5em;
}

.ab-live-indicator {
  height: 2ex;
}

.ab-control-room-view .ag-header {
  font-size: 24px !important;
}

.ab-control-room-view .ag-cell {
  padding: 8px;
  display: flex;
  align-items: center;
}
.ab-control-room-view .ag-cell-wrapper {
  width: inherit;
  word-wrap: break-word;
  padding-right: 8px;
}
.ag-row {
  font-family: "Karla" !important;
  line-height: 28px;
  height: 40px;
  font-weight: 400 !important;
}
.ag-row:nth-child(even) {
  background: #222627;
}

.ag-row:nth-child(odd) {
  background: #181d20;
}

@media screen and (max-device-width: 895px) {
  .ag-header {
    min-height: unset !important;
    height: 32px !important;
  }

  .ag-header-row {
    height: 32px !important;
  }

  .ag-cell {
    line-height: 32px !important;
  }
}

.per-page,
.change-time,
.search-sort {
  margin-left: 5px;
}

.change-time {
  background-color: #000000;
  color: white;
  cursor: pointer;
  text-decoration: underline;
  border: 0px;
}
.timeFormat-12,
.timeFormat-24,
.timeFormat {
  background-color: #000000;
  cursor: pointer;
  border: 0px;
  font-size: 15px;
}

.timeFormat-12,
.timeFormat-24 {
  color: white;
}

.timeFormat-12 {
  margin-left: 4px;
}

.timeFormat {
  color: grey;
}

.navigations {
  position: absolute;
  color: white;
  display: inline;
}

.perpage {
  width: 50px;
}
